import React from "react";
import { connect } from "react-redux";
import { preferredLocationSelector } from "tsi-common-react/src/apps/common/selectors";
import {
    TDispatchMapper,
    TStateMapper,
} from "tsi-common-react/src/apps/reducers.interfaces";
import { Dispatchers } from "tsi-common-react/src/apps/retail/dispatchers";
import {
    filteredStoreSelector,
    focusedStoreIDSelector,
    storeFilterSelector,
} from "tsi-common-react/src/apps/retail/selectors";
import {
    ILocation,
    IRetailStoreWithDistance,
} from "tsi-common-react/src/models/location.interfaces";
import { IMiles, ISyncStoreID } from "tsi-common-react/src/models/nominals";
import { formatPhoneNumber } from "tsi-common-react/src/utils/format";

import { RetailLocatorBlock } from "../components/RetailLocator";

interface IOwnProps {}

interface IReduxProps {
    stores: IRetailStoreWithDistance[];
    focusedStoreID: ISyncStoreID | null;
    location: ILocation | null;
    distanceFilter: IMiles;
}

interface IDispatchProps {
    dispatchers: Dispatchers;
}

interface IProps extends IOwnProps, IReduxProps, IDispatchProps {}

interface IState {}

class RetailLocatorContainer extends React.Component<IProps, IState> {
    private readonly onBeforeInfoWindowOpen = (
        info: google.maps.InfoWindow,
        store: IRetailStoreWithDistance,
    ) => {
        this.props.dispatchers.setFocusedStore(store.external_id);
        const content = `
            <div><div class="loc-name">${store.name}</div>
            <div>${store.address}</div>
            <div>${store.address2}</div>
            <div>${store.city}, ${store.state} ${store.postal}</div>
            <div>${store.phone ? formatPhoneNumber(store.phone) : ""}</div>
        `;
        info.setContent(content);
    };

    private readonly onInfoWindowClose = () => {
        this.props.dispatchers.setFocusedStore(null);
        const locationsList = document.querySelector("div.locations");
        if (locationsList) {
            locationsList.scrollTop = 0;
        }
    };

    render() {
        return (
            <RetailLocatorBlock
                location={this.props.location}
                stores={this.props.stores}
                focusedStoreID={this.props.focusedStoreID}
                onBeforeInfoWindowOpen={this.onBeforeInfoWindowOpen}
                onInfoWindowClose={this.onInfoWindowClose}
            />
        );
    }
}

const mapStateToProps: TStateMapper<"retail", IReduxProps, IOwnProps> = (
    rootState,
    ownProps,
) => {
    return {
        location: preferredLocationSelector(rootState),
        stores: filteredStoreSelector(rootState),
        focusedStoreID: focusedStoreIDSelector(rootState),
        distanceFilter: storeFilterSelector(rootState).distance,
        ...ownProps,
    };
};

const mapDispatchToProps: TDispatchMapper<IDispatchProps> = (dispatch) => {
    const dispatchers = new Dispatchers(dispatch);
    return {
        dispatchers: dispatchers,
    };
};

export const RetailLocator = connect(
    mapStateToProps,
    mapDispatchToProps,
)(RetailLocatorContainer);
