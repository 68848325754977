import React from "react";
import { Provider } from "react-redux";
import { Dispatchers } from "tsi-common-react/src/apps/reviews/dispatchers";
import { readQueryParams } from "tsi-common-react/src/apps/reviews/history";
import {
    isoFormUUID,
    isoReviewsBrandID,
    isoReviewsProductTypeID,
    isoWebPageURL,
} from "tsi-common-react/src/models/nominals";
import { ReviewSortOption } from "tsi-common-react/src/models/reviews.interfaces";
import { strToBool } from "tsi-common-react/src/utils/format";
import { notEmpty, unique } from "tsi-common-react/src/utils/functional";
import { dynamicPlaceComponent } from "tsi-common-react/src/utils/react";
import { v4 as uuidv4 } from "uuid";

import { store } from "../store";

/**
 * Init facet values from the URL
 */
const dispatchers = new Dispatchers(store.dispatch);
const initialFacetValues = readQueryParams();
if (initialFacetValues.product_type_id) {
    const productIDs = `${initialFacetValues.product_type_id}`
        .split(",")
        .map((ptid) => {
            return ptid
                ? isoReviewsProductTypeID.wrap(parseInt(ptid, 10))
                : null;
        })
        .filter(notEmpty)
        .filter(unique);
    if (productIDs.length) {
        dispatchers.setProductTypes(productIDs);
    }
}
delete initialFacetValues.product_type_id;
dispatchers.setFacetValues(initialFacetValues);

/**
 * Render "Write Review" buttons
 */
dynamicPlaceComponent('[data-place-react="write-review-button"]', async () => {
    const { SingleProductCollapsibleWriteReviewForm } = await import(
        "tsi-common-react/src/apps/reviews/containers/SingleProductCollapsibleWriteReviewForm"
    );
    return (
        <Provider store={store}>
            <SingleProductCollapsibleWriteReviewForm
                formUUID={isoFormUUID.wrap(uuidv4())}
            />
        </Provider>
    );
}).catch(console.error);

/**
 * Render "Write Review" application
 */
dynamicPlaceComponent('[data-place-react="write-review"]', async (elem) => {
    const { SingleProductWriteReviewForm } = await import(
        "tsi-common-react/src/apps/reviews/containers/SingleProductWriteReviewForm"
    );
    const brand = elem.dataset.brand || "Sealy";
    const brandID = isoReviewsBrandID.wrap(
        parseInt(elem.dataset.reviewsBrandId || "8", 10),
    );
    const returnURL = elem.dataset.returnUrl
        ? isoWebPageURL.wrap(elem.dataset.returnUrl)
        : undefined;
    return (
        <Provider store={store}>
            <SingleProductWriteReviewForm
                formUUID={isoFormUUID.wrap(uuidv4())}
                brand={brand}
                brandID={brandID}
                returnURL={returnURL}
            />
        </Provider>
    );
}).catch(console.error);

/**
 * Render ReviewsViewer SPA
 */
dynamicPlaceComponent('[data-place-react="review-spa"]', async (elem) => {
    const { ReviewsViewer } = await import(
        "tsi-common-react/src/apps/reviews/containers/ReviewsViewer"
    );
    const brandID = isoReviewsBrandID.wrap(
        parseInt(elem.dataset.reviewsBrandId || "8", 10),
    );
    const displayedProductTypeIDWhitelist = JSON.parse(
        elem.dataset.reviewsProductTypeWhitelist || "",
    );
    const defaultSortOption = (elem.dataset.defaultSort ||
        "-created_datetime") as ReviewSortOption;
    const pageSize = parseInt(elem.dataset.pageSize || "6", 10);
    const showSources =
        (elem.dataset.showSources || "True").toLowerCase() === "true";
    const showAppliedFilters =
        (elem.dataset.showAppliedFilters || "false").toLowerCase() === "true";
    const reviewSourceFilter = showSources ? [] : ["Sealy"];
    const writeReviewURL = elem.dataset.writeReviewUrl
        ? elem.dataset.writeReviewUrl
        : undefined;
    return (
        <Provider store={store}>
            <ReviewsViewer
                brandID={brandID}
                defaultSortOption={defaultSortOption}
                displayedProductTypeIDWhitelist={
                    displayedProductTypeIDWhitelist
                }
                pageSize={pageSize}
                showAppliedFilters={showAppliedFilters}
                showSources={showSources}
                sourceFilterWhitelist={reviewSourceFilter}
                writeReviewURL={writeReviewURL}
                isCondensedView={true}
            />
        </Provider>
    );
}).catch(console.error);

/**
 * Render PDP Reviews Application
 */
dynamicPlaceComponent('[data-place-react="product-reviews"]', async (elem) => {
    const { ProductReviews } = await import(
        "tsi-common-react/src/apps/reviews/containers/ProductReviews"
    );
    const brandID = isoReviewsBrandID.wrap(
        parseInt(elem.dataset.reviewsBrandId || "8", 10),
    );
    const productUUIDs = JSON.parse(elem.dataset.reviewsProductUuids || "[]");
    const numReviews = parseInt(elem.dataset.numReviews || "", 10);
    const averageRating = parseFloat(elem.dataset.averageRating || "");
    const showProductsFilter = strToBool(
        elem.dataset.showProductsFilter || "no",
    );
    const showSourcesFilter = strToBool(elem.dataset.showSourcesFilter || "no");
    return (
        <Provider store={store}>
            <ProductReviews
                title={elem.dataset.reviewsTitle || ""}
                brandID={brandID}
                productUUIDs={productUUIDs}
                numReviews={numReviews}
                averageRating={averageRating}
                showWriteReviewButton={true}
                writeReviewFormUUID={isoFormUUID.wrap(uuidv4())}
                showProductsFilter={showProductsFilter}
                showSourcesFilter={showSourcesFilter}
                isCollapsedView={true}
                brand="Sealy"
            />
        </Provider>
    );
}).catch(console.error);
