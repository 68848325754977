import classNames from "classnames";
import React from "react";
import { Provider } from "react-redux";
import { IProduct } from "tsi-common-react/src/models/catalogue.interfaces";
import { isoProductCategoryID } from "tsi-common-react/src/models/nominals";
import { dynamicPlaceComponent } from "tsi-common-react/src/utils/react";

import { store } from "../store";

// Render Pillows Grid
dynamicPlaceComponent(
    '[data-place-react="mattresses-product-list"]',
    async (elem) => {
        const { ProductGrid } = await import(
            "tsi-common-react/src/apps/product-grid2/index"
        );
        const { PriceComparator, SortDirection } = await import(
            "tsi-common-react/src/apps/product-grid2/models"
        );
        const { PriceRangeGridFilter } = await import(
            "tsi-common-react/src/apps/product-grid2/filters"
        );
        const { AttributeValueGridSorter, PriceGridSorter, RatingGridSorter } =
            await import("tsi-common-react/src/apps/product-grid2/sorters");

        const filters = [
            new PriceRangeGridFilter({
                filter_id: "price",
                label: "Price",
                ranges: [
                    {
                        id: "",
                        type: "dual_bound_price_range",
                        value: {
                            id: "300_499",
                            lower_bound: {
                                comparator: PriceComparator.GTE,
                                amount: 300,
                            },
                            upper_bound: {
                                comparator: PriceComparator.LTE,
                                amount: 499,
                            },
                        },
                    },
                    {
                        id: "",
                        type: "dual_bound_price_range",
                        value: {
                            id: "500_699",
                            lower_bound: {
                                comparator: PriceComparator.GTE,
                                amount: 500,
                            },
                            upper_bound: {
                                comparator: PriceComparator.LTE,
                                amount: 699,
                            },
                        },
                    },
                    {
                        id: "",
                        type: "dual_bound_price_range",
                        value: {
                            id: "700_899",
                            lower_bound: {
                                comparator: PriceComparator.GTE,
                                amount: 700,
                            },
                            upper_bound: {
                                comparator: PriceComparator.LTE,
                                amount: 899,
                            },
                        },
                    },
                    {
                        id: "",
                        type: "dual_bound_price_range",
                        value: {
                            id: "900_1499",
                            lower_bound: {
                                comparator: PriceComparator.GTE,
                                amount: 900,
                            },
                            upper_bound: {
                                comparator: PriceComparator.LTE,
                                amount: 1499,
                            },
                        },
                    },
                ],
            }),
        ];

        const sorters = [
            new AttributeValueGridSorter({
                id: "firmest-to-softest",
                label: "Firmest to Softest",
                direction: SortDirection.DESC,
                attribute: "firmness",
            }),
            new AttributeValueGridSorter({
                id: "softest-to-firmest",
                label: "Softest to Firmest",
                direction: SortDirection.ASC,
                attribute: "firmness",
            }),
            new PriceGridSorter({
                id: "price-low-to-high",
                label: "Price Low to High",
                direction: SortDirection.ASC,
            }),
            new PriceGridSorter({
                id: "price-high-to-low",
                label: "Price High to Low",
                direction: SortDirection.DESC,
            }),
            new RatingGridSorter({
                id: "rating",
                label: "Rating",
                direction: SortDirection.DESC,
            }),
        ];

        const buildExternalCallout = (product: IProduct): React.ReactNode => {
            const in_store_only =
                product.attributes.in_store_only &&
                product.attributes.in_store_only.value;
            const callout = in_store_only
                ? "In Stores Only"
                : "Online Exclusive–Shop Now";
            const calloutClass = classNames({
                "product-grid-card__external-callout": true,
                "product-grid-card__online": !in_store_only,
                "product-grid-card__offline": in_store_only,
            });
            return <div className={calloutClass}>{callout}</div>;
        };

        const categoryID = isoProductCategoryID.wrap(
            parseInt(elem.dataset.categoryId || "1", 10),
        );
        return (
            <Provider store={store}>
                <ProductGrid
                    gridVariant="pillows"
                    productCategoryID={categoryID}
                    filters={filters}
                    sorters={sorters}
                    disableHoverOverlay={true}
                    buildExternalCallout={buildExternalCallout}
                />
            </Provider>
        );
    },
).catch(console.error);
