import React from "react";
import { Provider } from "react-redux";
import { registerCascades } from "tsi-common-react/src/apps/retail/cascades";
import { dynamicPlaceComponent } from "tsi-common-react/src/utils/react";
import { urls } from "tsi-common-react/src/utils/urls";

import { store } from "../store";

// Register store cascades
registerCascades(store, undefined, {
    has_slot_sealy: "Y",
    distance: 50,
});

dynamicPlaceComponent('[data-place-react="retail-cards"]', async () => {
    const { RetailCards } = await import(
        "tsi-common-react/src/apps/retail/containers/RetailCards"
    );
    return (
        <Provider store={store}>
            <RetailCards retailLocatorLink={urls.pageURL("find-a-retailer")} />
        </Provider>
    );
}).catch(console.error);

dynamicPlaceComponent('[data-place-react="retail-locator"]', async () => {
    const { RetailLocator } = await import("./containers/RetailLocator");
    const { SurveyModal } = await import("./containers/SurveyModal");
    return (
        <Provider store={store}>
            <RetailLocator />
            <SurveyModal />
        </Provider>
    );
}).catch(console.error);
