import React from "react";
import { createPortal } from "react-dom";
import SVG from "react-inlinesvg";
import { Provider } from "react-redux";
import { snapUserDataLayerState } from "tsi-common-react/src/api/datalayer";
import { registerCascades } from "tsi-common-react/src/apps/common/cascades";
import { Dispatchers } from "tsi-common-react/src/apps/common/dispatchers";
import { Loaders } from "tsi-common-react/src/apps/common/loaders";
import { initCSRToolbar } from "tsi-common-react/src/apps/csr";
import { isoWebPageURL } from "tsi-common-react/src/models/nominals";
import { UGCListSubBlock } from "tsi-common-react/src/models/ugc";
import { readyStateComplete } from "tsi-common-react/src/utils/events";
import { strToBool } from "tsi-common-react/src/utils/format";
import { dynamicPlaceComponent } from "tsi-common-react/src/utils/react";
import { urls } from "tsi-common-react/src/utils/urls";

import { rehydratingStore, store } from "../store";
import iconAdd from "../svg/add.svg";
import { getMenuConfiguration } from "./menu";

interface IUGCGridData {
    title: string;
    desc: string;
    posts: UGCListSubBlock[];
    cta: number;
}

export const dispatchers = new Dispatchers(store.dispatch);
export const loaders = new Loaders(dispatchers);

// Register store cascades
registerCascades(store);

// Load data for main menu from Wagtail JSON and render main menu
const menuConfig = getMenuConfiguration();
if (menuConfig) {
    dynamicPlaceComponent(
        '[data-place-react="main-menu-dropdown"]',
        async () => {
            const { MainMenu } = await import("./containers/MainMenu");
            return (
                <MainMenu
                    isAdmin={!(document.querySelector(".csr-toolbar") === null)}
                    menuItems={menuConfig}
                />
            );
        },
    ).catch(console.error);
}

// Render account menu
dynamicPlaceComponent('[data-place-react="auth-login-menu-link"]', async () => {
    const { AccountMenu } = await import(
        "tsi-common-react/src/apps/authentication/components/AccountMenu"
    );
    return (
        <Provider store={store}>
            <AccountMenu />
        </Provider>
    );
}).catch(console.error);

// Render standalone login forms
dynamicPlaceComponent(
    '[data-place-react="auth-login-standalone"]',
    async (elem) => {
        const { LoginForm } = await import(
            "tsi-common-react/src/apps/authentication/components/LoginForm"
        );
        const nextURL = elem.dataset.continueUrl
            ? isoWebPageURL.wrap(elem.dataset.continueUrl)
            : undefined;
        return <LoginForm nextURL={nextURL} />;
    },
).catch(console.error);

// Render Session Keep-Alive Modal
dynamicPlaceComponent(
    '[data-place-react="session-keep-alive-modal"]',
    async () => {
        const { SessionKeepAlive } = await import(
            "tsi-common-react/src/apps/authentication/components/SessionKeepAlive"
        );
        return <SessionKeepAlive />;
    },
).catch(console.error);

// Render Value Props Bar
dynamicPlaceComponent('[data-place-react="value-props-bar"]', async (elem) => {
    const { ValuePropsBar } = await import(
        "tsi-common-react/src/common/ValuePropsBar"
    );
    const parsedData = JSON.parse(elem.dataset.cms || "");
    return <ValuePropsBar valueProps={parsedData.value_props} />;
}).catch(console.error);

// Place UGC Grid component
dynamicPlaceComponent('[data-place-react="ugc-grid"]', async (elem) => {
    const { UGCGrid } = await import("tsi-common-react/src/common/UGCGrid");
    const ugcGridData: IUGCGridData = JSON.parse(elem.dataset.posts || "");
    await readyStateComplete;
    return <UGCGrid tiles={ugcGridData.posts} />;
}).catch(console.error);

// Render tooltips for product_advert block
dynamicPlaceComponent(
    '[data-place-react="product-advert-tooltip"]',
    async (elem) => {
        const ReactTooltip = (await import("react-tooltip")).default;
        const tipID = `tooltip-${elem.id}`;
        const title = elem.dataset.tooltipTitle || "";
        const description = elem.dataset.tooltipDescription || "";
        const rect = elem.getBoundingClientRect();
        const offsetLeft = rect.left + document.body.scrollLeft;
        const bodyWidth = document.body.offsetWidth;
        const offsetRight = bodyWidth - offsetLeft;
        let placement: "right" | "left" | "top" | "bottom";
        let width: number;
        if (offsetLeft < bodyWidth / 3) {
            placement = "right";
            width = Math.min(300, bodyWidth - offsetLeft - 20);
        } else if (offsetRight < bodyWidth / 3) {
            placement = "left";
            width = Math.min(300, offsetRight - 20);
        } else {
            placement = "top";
            width = Math.min(300, bodyWidth - 20);
        }
        return (
            <>
                <span
                    data-tip={true}
                    data-for={tipID}
                    className="banner__tooltip-icon-container"
                >
                    <SVG
                        title="Plus Icon"
                        className="banner__tooltip-icon"
                        src={iconAdd}
                    />
                </span>
                {createPortal(
                    <ReactTooltip
                        id={tipID}
                        isCapture={true}
                        role="tooltip"
                        type="light"
                        clickable={true}
                        multiline={true}
                        effect="float"
                        place={placement}
                    >
                        <div style={{ width: width }}>
                            <h2
                                dangerouslySetInnerHTML={{ __html: title }}
                            ></h2>
                            <p>{description}</p>
                        </div>
                    </ReactTooltip>,
                    document.body,
                )}
            </>
        );
    },
).catch(console.error);

// Place Live Chat Links
dynamicPlaceComponent('[data-place-react="chat-link"]', async (elem) => {
    const { ChatLink } = await import(
        "tsi-common-react/src/apps/chat/ChatLink"
    );
    return (
        <ChatLink
            className={elem.dataset.chatLinkClass}
            chatOnlineText={elem.dataset.chatOnlineText}
            chatOfflineText={elem.dataset.chatOfflineText}
            chatOfflineLink={
                elem.dataset.chatOfflineLink
                    ? isoWebPageURL.wrap(elem.dataset.chatOfflineLink)
                    : urls.pageURL("customer-service")
            }
        />
    );
}).catch(console.error);

dynamicPlaceComponent('[data-place-react="footer-widgets"]', async (elem) => {
    const { FooterWidgets } = await import(
        "tsi-common-react/src/apps/common/containers/FooterWidgets"
    );
    return (
        <Provider store={store}>
            <FooterWidgets
                phoneNumber={elem.dataset.phoneNumber || ""}
                showContactSupportWidget={strToBool(
                    elem.dataset.showContactSupport || "",
                )}
                showNearbyStoresWidget={strToBool(
                    elem.dataset.showNearbyStores || "",
                )}
            />
        </Provider>
    );
}).catch(console.error);

// Render the CSR toolbar
initCSRToolbar({
    rehydratingStore,
    loaders,
    store,
})
    .then(() => {
        const headerElem = document.querySelector<HTMLElement>("header");
        const csrToolbar =
            document.querySelector<HTMLElement>("#oscarcsr-toolbar");
        if (headerElem && csrToolbar) {
            const toolbarOffset =
                window.innerWidth <= 768
                    ? csrToolbar.offsetHeight * 2
                    : csrToolbar.offsetHeight;
            headerElem.style.top = `${toolbarOffset || 0}px`;
        }
    })
    .catch(console.error);

// Render Service Footer
dynamicPlaceComponent('[data-place-react="service-footer"]', async (elem) => {
    const { ServiceFooter } = await import(
        "tsi-common-react/src/apps/common/containers/ServiceFooter"
    );
    const snippets = JSON.parse(elem.dataset.snippets || "null");
    const interval = Number(elem.dataset.interval) || null;
    const loops = Number(elem.dataset.loops) || null;

    return (
        <Provider store={store}>
            <ServiceFooter
                snippets={snippets.snippets}
                interval={interval}
                loops={loops}
            />
        </Provider>
    );
}).catch(console.error);

// Render Pre Approval Triggers for Homepage
dynamicPlaceComponent(
    '[data-place-react="financing-modal-trigger--financing-banner-block"]',
    async (elem) => {
        const { FinancingBannerBlock } = await import(
            "./components/FinancingBannerBlock"
        );
        const applicationSource =
            elem.dataset.applicationSource || "Financing Page";
        return (
            <Provider store={store}>
                <FinancingBannerBlock applicationSource={applicationSource} />
            </Provider>
        );
    },
).catch(console.error);

// Render warranty form
dynamicPlaceComponent('[data-place-react="warranty-form"]', async (elem) => {
    const { WarrantyForm } = await import(
        "tsi-common-react/src/common/WarrantyForm"
    );

    const buttons =
        document.querySelectorAll<HTMLAnchorElement>("a[class='button']");
    if (buttons) {
        const claimBtn = Array.from(buttons).find((el) => {
            return el.innerText.toLowerCase().includes("claim");
        });

        if (claimBtn) {
            claimBtn.removeAttribute("href");
        }
    }
    const content = JSON.parse(elem.dataset.cms || "");
    const url =
        "https://help.sealy.com/hc/en-us/requests/new?ticket_form_id=1500003086321";

    return <WarrantyForm cms={content} propertyName="Sealy" claimURL={url} />;
}).catch(console.error);

// Render user data layer element
dynamicPlaceComponent('[data-place-react="user-data-layer"]', async (elem) => {
    await readyStateComplete;
    // Pause for 1 second to allow the data layer push scripts to run after readyState is complete
    await (async () => {
        return new Promise((res) => {
            setTimeout(res, 1000);
        });
    })();
    const orderID = elem.dataset.order || "";
    await snapUserDataLayerState(orderID);
    return null;
}).catch(console.error);
